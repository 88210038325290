import '../sass/app.scss'

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo'
import lodash from 'lodash'
import axios from 'axios'
import Popper from 'popper.js'
import jQuery from 'jquery'
import 'bootstrap'
import Pusher from 'pusher-js'

window._ = lodash

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

window.Popper = Popper.default
window.$ = window.jQuery = jQuery

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

window.Pusher = Pusher

window.Echo = new Echo({
    broadcaster: 'pusher',
    // eslint-disable-next-line no-undef, camelcase
    key: echo_key,
    // eslint-disable-next-line no-undef, camelcase
    cluster: echo_cluster,
    forceTLS: true
})
